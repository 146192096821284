@font-face {
  font-family: 'Redaction';
  src: url('./fonts/Redaction35-Regular.otf') format('opentype');
}

.App {
  text-align: center;
  background-color: #000000;
  color: white;
}

.herobox{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.herotext{
  font-size: 3rem;
  font-weight: 400;
  font-family: 'Redaction', sans-serif;
}

.herosubtext{
  font-size: 1.05rem;
  font-weight: 300;
  font-family: roboto mono, monospace;
  width: 50%;
  height: auto;
  margin: 20px auto;
}

.cta-button{
  background-color: #000000;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  padding: 1.1rem 2.5rem;
  border-radius: 0.5rem;
  border-color: white;
  margin: 2rem 0px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: roboto mono, monospace;
}

.cta-button2{
  background-color: #000000;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  padding: 1.1rem 2.5rem;
  border-radius: 0.5rem;
  border-color: white;
  margin: 2rem 0px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: roboto mono, monospace;
}


.contextbox{
  text-align: left;
  width: 800px;
  margin: 0px auto;
}


.image1{
  width: 400px;
}

.image2{
  width: 550px;
}

.how-it-works{
  margin: 200px 0px;
}

.section-title{
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 2rem;
  font-family: 'Redaction', sans-serif;
}

.section-p{
  font-size: 1rem;
  width: 800px;
  margin: auto;
  font-weight: 300;
  font-family: roboto mono, monospace;
  color: rgba(255, 255, 255, 0.665);
  /* text-align: left; */
}

.problem-solution{
  margin: 200px 0px;
}

.join-beta{
  margin: 200px 0px;
}


footer {
  background-color: black;
  color: rgba(255, 255, 255, 0.665);
  padding: 5rem;
  text-align: center;
  font-family: roboto mono, monospace;
  font-size: small;
}

footer p {
  margin: 0.5rem 0;
}

footer a {
  color: #61dafb;
  text-decoration: none;
  transition: color 0.3s ease;
}

footer a:hover {
  color: white;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media (max-width: 768px) {
  .herotext {
    font-size: 3rem; /* Smaller font size for smaller screens */
    width: 50%;
    margin: auto;
  }

  .image1{
    width: 300px;
  }
  .image2{
    width: 400px;
  }

  .herosubtext, .section-p {
    font-size: 0.95rem; /* Adjust font size for readability */
  }

  .cta-button {
    padding: 0.8rem 2rem; /* Adjust button padding for smaller screens */
  }

  .contextbox, .section-p, .section-title {
    width: 85%; /* Allow more margin on the sides */
  }

  .section-title {
    margin: 2rem auto;
  }

  .cta-button2 {
    margin: 3rem 2rem;
  }

}

/* Additional Media Query for very small screens, like smartphones */
@media (max-width: 480px) {
  .herotext {
    font-size: 1.5rem; /* Even smaller font size for very small screens */
  }

  .herosubtext, .section-p {
    font-size: 0.9rem; /* Further adjust for readability on small devices */
  }

  .cta-button {
    padding: 0.7rem 1.5rem; /* Smaller buttons on very small screens */
  }
}